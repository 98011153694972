<template>
    <div class="container">
        <schedule :plan-year='planYear' />
    </div>
</template>

<script>
import Core from '@/core';
import Schedule from './components/schedule/Schedule';

export default {
    components: {
        Schedule
    },
    data() {
        return {
            planId: '',
            planYear: '',
        }
    },
    created() {
        this.planId = this.$route.query.plan_id;
        this.getPlanYear();
    },
    methods: {
        getPlanYear() {
            Core.Api.Plan.detail(this.planId).then(res => {
                this.planYear = res.detail.year
                console.log('this.planYear:', this.planYear)
            });
        },
    },
};
</script>
<style lang="scss" scoped>
</style>
